.DeviceSearchRoot {
    position: relative;
    padding: 24px;
}

.DeviceSearchItemProgressView {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: table;
    font-size: 1.5em;
}

.DeviceSearchItem {
    margin: 8px;
    overflow: hidden;
}

.DeviceSearchItemDiv {
    width: 150px;
    height: 200px;
}

.DeviceSearchItemImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.InProgressOpacity {
    opacity: 0.2;
}

.DeviceSearchFolderName {
    margin-top: -20px;
    margin-bottom: 16px;
}

.DeviceSearchButtons {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 24px;
    margin-bottom: 16px;
}

.DeviceSearchItemTitle {
    width: 100%;
    padding: 4px;
    box-sizing: border-box;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.85);
    bottom: 0;
    left: 0;
    color: black;
    text-align: center;
    font-size: 1.1em;
}

.IconButton {
    background-color: #f5f5f5 !important;
}