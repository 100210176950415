body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Root {
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-bottom: 16px;
    white-space: pre-line;
}

.FaceExampleContainer {
    margin: 8px;
}

.FaceExampleAvatarContainer {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
}

.FaceToSearchImageContainer {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 8px;
    background-color: #f5f5f5;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
}

.SelferAd {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: #f6f9fdea;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: small;
    padding: 8px 0px;
    text-transform: uppercase;
    color: grey;
}

.SelferAd b {
    color: #2962ff;
}

.FaceImageButton {
    width: 100%;
    height: 100%;
}

.FaceImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.SocialButton {
    width: 84px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px;
}

.SocialLinks {
    margin-top: 16px;
}

.PrivacyPolicyLink {
    margin: 8px 16px 24px;
}

.PrivacyPolicyLink a {
    color: black;
}

.HorizontalScroll {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.Title {
    font-size: 14pt;
    color: black;
}

.Subtitle {
    font-size: 12pt;
    color: grey;
}

.ScaleDownFit {
    object-fit: scale-down;
}

.FaceToSearchContainer {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 8px;
}

.FaceToSearchContainer div {
    position: absolute;
}

.FaceToSearchAvatar {
    background-color: lightgrey;
}

.FaceRecognitionConfigProgress {
    margin: 32px;
}

.SearchTypesContainer {
    margin: auto;
    padding: 16px 32px 16px;
    max-width: 400px;
}

.Disabled {
    opacity: 0.5;
}

.Hidden {
    display: none;
}

.IhancerAd {
    margin: 8px;
    margin-top: 8px;
    margin-bottom: 24px;
    color: #E0E0E0;
}

.IhancerAd a {
    padding: 6px 16px;
    line-height: 1.8em;
    background: linear-gradient(90deg, #d0c3a7, #fbefd9);
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    color: #8f6820;
    text-decoration: none;
    border-radius: 4px;
}

.IhancerAd span {
    -webkit-animation: 6.5s infinite image_blur;
            animation: 6.5s infinite image_blur;
}

@-webkit-keyframes image_blur {
    0% { -webkit-filter: blur(1.8px);}
    75% { -webkit-filter: blur(0px);}
    100% { -webkit-filter: blur(1.8px);}
}

@keyframes image_blur {
    0% { -webkit-filter: blur(1.8px);}
    75% { -webkit-filter: blur(0px);}
    100% { -webkit-filter: blur(1.8px);}
}

.AppDescription {
    margin: auto;
    max-width: 400px;
    text-align: justify;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
}

.WarInfo {
    margin: auto;
    width: 250px;
    height: 230px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding: 8px;
    padding-top: 16px;
    font-size: 18pt;
    text-decoration: none;
    background-size: cover;
    margin-bottom: 16px;
    border-radius: 8px;
}

.WarInfo:link, .WarInfo:visited {
    color: white;
}
.BaseSnackbarRoot {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}
.DeviceSearchRoot {
    position: relative;
    padding: 24px;
}

.DeviceSearchItemProgressView {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: table;
    font-size: 1.5em;
}

.DeviceSearchItem {
    margin: 8px;
    overflow: hidden;
}

.DeviceSearchItemDiv {
    width: 150px;
    height: 200px;
}

.DeviceSearchItemImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.InProgressOpacity {
    opacity: 0.2;
}

.DeviceSearchFolderName {
    margin-top: -20px;
    margin-bottom: 16px;
}

.DeviceSearchButtons {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 24px;
    margin-bottom: 16px;
}

.DeviceSearchItemTitle {
    width: 100%;
    padding: 4px;
    box-sizing: border-box;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.85);
    bottom: 0;
    left: 0;
    color: black;
    text-align: center;
    font-size: 1.1em;
}

.IconButton {
    background-color: #f5f5f5 !important;
}
